import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadCompanyFacets } from '../redux/actions/filters';
import { headCountSelector, industryTagsSelector, stageSelector } from '../redux/selectors/filters';
import { makeSelectIsLoading } from '../redux/selectors/loading';
import { mapHeadCountToInterval, companyStageFormatter } from '../helpers/stringHelper';

const groupHeadcountData = (data) =>
  data.reduce(
    (acc, item) => {
      const value = mapHeadCountToInterval(item.value);

      if (value === acc.grouped) return acc;

      acc.grouped = value;
      acc.groups.push({
        name: `${value} employees`,
        value: item.value,
        id: value,
      });
      return acc;
    },
    { grouped: '', groups: [] },
  ).groups;

export const useCompanyFacets = ({ network, origin }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(makeSelectIsLoading('companiesHeadcount'));
  const headCounts = useSelector(headCountSelector);
  const stages = useSelector(stageSelector);
  const industryTags = useSelector(industryTagsSelector);

  useEffect(() => {
    dispatch(
      loadCompanyFacets({
        network,
        origin,
      }),
    );
  }, [dispatch, network, origin]);

  const newStages = useMemo(() => {
    const stageSet = new Set(stages.map((stage) => stage.value));
    return [
      'pre_seed',
      'seed',
      'series_a',
      'series_b',
      'series_c',
      'series_c_plus',
      'private_equity',
      'ipo',
      'ico',
      'other',
    ]
      .filter((stage) => stageSet.has(stage))
      .reduce(
        (acc, stage) => [
          ...acc,
          {
            id: stage,
            name: companyStageFormatter(stage),
            value: stage,
          },
        ],
        [],
      );
  }, [stages]);

  const newIndustryTags = useMemo(
    () =>
      industryTags.map((item) => ({
        id: item.value,
        name: item.value,
        value: item.value,
        count: item.count,
      })),
    [industryTags],
  );

  return {
    isLoading,
    headCounts: useMemo(() => groupHeadcountData(headCounts), [headCounts]),
    stages: newStages,
    industryTags: newIndustryTags,
  };
};
